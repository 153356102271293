@import"colors";

.zim {
    max-width: 1650px;
    margin: auto;
}

.home {
    width: 100%;
    height: 100vh;
    background-color: $second;


    >main {
        position: absolute;

        text-transform: uppercase;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 2rem;


        h1 {
            font: 400 6rem $text1;
            color: $first;
        }

        P {
            font: 900 1rem $text1;
            color: $first;
        }

    }

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;

        background: no-repeat center/auto url("../assets/pic1.png");
        filter: blur(2px);
        border-radius: 0px 0px 200px 200px;
    }
}

.home2 {
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -200px;
    padding: 500px 0px;

    img {
        width: 30%;
        animation: imgUp 0.7s linear infinite alternate;
    }


    div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5rem;
        height: 100vh;

        p {
            color: $first;
            letter-spacing: 2px;
            word-spacing: 5px;
            font: 100 1.2rem $text2;
        }
    }

}


@keyframes imgUp {
    to {
        transform: translateY(-10px);
    }
}


.home3 {
    width: 100%;
    height: 120vh;
    background-color: $second;
    margin-top: -200px;
    display: flex;

    >div {
        width: 70%;
        height: 100vh;
        background-color: $first;
        border-radius: 0 200px 200px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;


        h1 {
            text-transform: uppercase;
            border-bottom: 2px solid $forth;
            padding: 0.5rem;
            width: 15rem;
            color: $forth;
        }

        p {
            padding: 5rem;
            letter-spacing: 1px;
            word-spacing: 5px;
            line-height: 170%;
            font: italic 100 1.2rem $text2;
        }
    }
}


.home4 {
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    justify-content: flex-end;

    >div {
        width: 70%;
        height: 70vh;
        background-color: $third;
        border-radius: 200px 0 0 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;

        >h1 {
            color: $first;
            text-transform: uppercase;
            width: 140px;
            margin-bottom: 2px solid $first;
            padding: 0.5rem;
        }

        >article {
            display: flex;
            margin: 2rem 0;
            flex-wrap: wrap;
            justify-content: center;

            >div {
                margin: 1rem;
                animation: imgUp 0.7s linear infinite alternate;

                height: 10rem;
                width: 10rem;
                background-color: $first;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: $forth;
                cursor: pointer;
                transition: all 0.5s;

                &:hover {
                    filter: invert(1);
                }

                svg {
                    font-size: 4rem;
                }

                P {
                    font: 900 1.2rem $text2;
                }
            }
        }

    }

}