@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Borel&family=Dancing+Script:wght@500&family=Edu+SA+Beginner&family=Maven+Pro:wght@500&family=Montserrat:wght@500&family=Oswald:wght@300;500&family=Playfair+Display:ital,wght@1,500&family=Raleway:ital,wght@0,300;0,500;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,500&family=Smooch&family=Source+Sans+3:wght@500&display=swap');

*{

    margin: 0px;
    padding: 0px;
    font-family: "roboto";
    box-sizing: border-box;
    text-decoration: none;
    scroll-behavior: smooth;

}