.footer1{
    /* max-width: 1350px; */
    margin:   auto;


}
.part1f{
    height: 400px;
     background-color: black;
    display: flex;
 color: aliceblue;
 /* margin-top: 50px; */
    justify-content: center;
}
.part1-1f{

    margin-top: 100px ;
  
}
.you2 i{
margin-right: 10px;
}
.you3 i{
margin-right: 14px;
}


.part1-1fo{
margin-top: 10px;
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    margin-right: 230px;
    cursor:pointer;
    font-size: 25px;
}
.you{
    
    margin-top:15px;
}
.you a{
    text-decoration: none;
color: white;
font-family: "dosis";
}
.you a:hover{
color:blueviolet;
}
.part1-2f{
    
    display: flex;
    justify-content: center;
    margin-top: 100px;
    
    flex-direction: row;
}


.part1-2f p{
    font-family: "dosis";
    font-size: 17px;
    line-height: 27px;
    font-weight: lighter;
}
.part1-2fa h4{
font-family: "oswald";
margin-bottom: 10px;
font-size: 27px;
font-weight: 300;
line-height: 23px;

}
.part1-2fa h4:hover{
    color: blueviolet;
}
.part1-2fa p:hover{
    color: blueviolet;
}
.part1-2fo{
    margin-left: 230px;
}

.part1-2fo h4{
    margin-bottom: 10px;
    font-family: "oswald";
    font-size: 27px;
    font-weight: 300;
    line-height: 23px;
}
.part1-2fo h4:hover{
    color: blueviolet;
}
.part1-2fo p:hover{
    color: blueviolet;
}